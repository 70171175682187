/* You can add global styles to this file, and also import other style files */
@import "./node_modules/@thg-procure-team/procure-common-ui/resources/scss/icons";

body {
  display: none;
}

th.w-60 {
  width: 60px;
}

.tooltip__criteria-error {
  min-width: 600px;
}

.tooltip-approval-matrix-users {
  min-width: 380px;
  text-align: center;
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .btn-lg-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .btn-md-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .btn-sm-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (max-width: 575px) {
  .btn-xs-block {
    width: 100% !important;
    display: block !important;
  }
}

.p-dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-120px {
  width: 120px;
}

select#page-size {
  margin-left: 10px;
}

// Overrides primeng styling
.p-autocomplete-clear-icon {
  position: relative !important;
  margin-top: auto !important;
  cursor: pointer;
}
